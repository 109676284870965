import mmimage from "../assets/Maze_Mind_1.0.jpg"

            // <div className="flex items-center p-11 h-12 w-full shadow-2xl shadow-lime-500 bg-red-700">
            //     <h1 className="font-scp text-white font-bold text-5xl">MAZE MIND</h1>
            // </div>
            // <div className="flex flex-row w-full h-full">
            //     <div>
            //         tekst ouwe
            //     </div>
            // </div>

function Landing() {
    return(
        <div className="h-full w-full">
            <div className="h-full">
                <div className="md:flex sm:flex-row justify-center items-center md:p-20 w-full md:h-full">
                    <div className="w-full justify-center items-center md:w-2/3 md:relative">
                        {/* MEDIUM+ SCREEN */}
                        <div className="fixed invisible md:visible">
                            <h1 className="font-scp text-7xl text-sky-400 font-extrabold ">MAZE MIND</h1>
                        </div>
                        {/* MOBILE */}
                        <div className="visible md:invisible">
                            <h1 className="text-center font-scp text-5xl text-rose-600 font-extrabold my-10">MAZE MIND</h1>
                        </div>
                        <div className="flex justify-center items-center">
                            <img src={mmimage} alt="" className="sm:w-full md:w-10/12 md:rounded-3xl" />
                        </div>
                    </div>
                    <div className="w-full md:w-1/3">
                        <div className="flex justify-center items-center space-y-10 flex-col">
                            <p className="invisible md:visible md:mt-32"></p>
                            <p className="text-sky-400 font-bold text-lg md:text-xl lg:text-4xl text-center font-scp">info[at]mazemind.band</p>
                            <iframe title="spotify-widget" className="w-4/5 md:w-full h-20 md:h-40" src="https://open.spotify.com/embed/artist/3pnZITCC0FJXjBeFAukmqI?utm_source=generator&theme=0"  frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>
                            <iframe title="deezer-widget" className="w-4/5 md:w-full h-20 md:h-40" src="https://widget.deezer.com/widget/dark/album/326430917?tracklist=false"  frameborder="0" allowtransparency="true" allow="encrypted-media; clipboard-write"></iframe>
                            <div className="flex flex-row w-full justify-around sm:mb-40">
                                <a href="https://www.instagram.com/mazemindband/" target="_blank" rel="noreferrer">
                                    <i className="text-sky-400 text-4xl md:text-6xl fab fa-instagram"></i>
                                </a>
                                <a href="https://www.facebook.com/AAazeAAind/" target="_blank" rel="noreferrer">
                                    <i className="text-sky-400 text-4xl md:text-6xl fab fa-facebook"></i>
                                </a>
                                <a href="https://open.spotify.com/artist/3pnZITCC0FJXjBeFAukmqI" target="_blank" rel="noreferrer">
                                    <i className="text-sky-400 text-4xl md:text-6xl fab fa-spotify"></i>
                                </a>
                                <a href="https://www.deezer.com/en/artist/146772072" target="_blank" rel="noreferrer">
                                    <i className="text-sky-400 text-4xl md:text-6xl fab fa-deezer"></i>
                                </a>
                            </div>
                            <p className="visible md:invisible sm:mb-20"></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Landing;