import Landing from '../components/Landing';

function App() {
  return (
    <div className="flex w-screen min-h-screen md:min-h-screen bg-sky-950">
      <Landing/> 
    </div>
  );
}

export default App;
